@import 'src/style/mixins.scss';

.select__control--is-disabled{
    cursor: not-allowed;
}

.Select {
  height: 32px;
  &__success, &__success:hover{
    background-color: $success100;
    border-color: $success;
  }
  &__error, &__error:hover{
    background-color: $error100;
    border-color: $error;
  }
  &__disabled, &__disabled:hover{
    background-color: $grey100;
    border-color: $grey100;
    cursor: not-allowed;
    input, input::placeholder{
      color: $grey300;
    }
  }
}
.SelectSearch_control{
  // cursor: pointer;
  // background: #EFECFA;
  // border-radius: 100px;
  // border: 0px solid rgba(0, 0, 0, 0);
  // font-weight: 500;
  // font-size: 14px;
  // line-height: 21px;
  // color: #2D0E6B;
  // padding: 8px 32px;
  // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);

}

.Select__fullWidth{
  width: 100%;
  .SelectSearch{
    width: 100%;
  }
}
.Select{
  .indicators{
    background-color: red;
  }
  // [class$="-control"]{
  //   padding: 0;
  //   // background-color: red;
  // }
  // [class$="dropdown-indicator "]{
  //   background-color: red;
  //   padding: 0;
  //   width: 16px;
  //   height: 16px;
  // }
}

@include desktopHd{
  .SelectSearch{
    >div{
      padding: 8px;
      width: calc(100%);
    }
  }
}
* {
  $dimension:     4px;
  $trackColor:    $auxilary;
  $thumbColor:    $primary;
  scroll-margin-top: rem(0);

  /* Firefox */
  scrollbar-color: $thumbColor $trackColor; /* thumb and track color */
  scrollbar-width: thin; /* value: none, thin, auto */
  /* Webkit - Chrome */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: $dimension;
    width: $dimension;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumbColor;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-track {
    background: $trackColor;
    border-radius: 100px;
    margin: 48px;
  }
}
@use 'sass:math';
// barvy

$primary100: #c0b7d3;
$primary200: #9687b5;
$primary300: #6c5697;
$primary400: #42267a;
$primary500: #2d0e6b;
$primary600: #290d60;
$primary700: #240b56;
$primary800: #1f0a4b;
$primary900: #1b0840;

$secondary100: #fdf1da;
$secondary200: #fbe2b5;
$secondary300: #f9d490;
$secondary400: #f7c56b;
$secondary500: #f5b746;
$secondary700: #c49238;

$success100: #e1f9d9;
$success700: #52b235;

$error100: #f8dbdc;
$error700: #b23a42;

$grey100: #e6e6e6;
$grey200: #cccccc;
$grey300: #b3b3b3;
$grey400: #999999;
$grey500: #808080;

//barvy základní

$primary: $primary500;
$secondary: #f5b746;
$auxilary: #efecfa;
$textBackground: #f7f5fc;

$success: #67df42;
$alert: $secondary;
$error: #de4952;
$focused: rgba(2, 99, 224, 0.5);

$white: #fff;
$black: #000000;

$transparent: rgba(0, 0, 0, 0);

//typografie

//breakpointy

$grid-breakpoints: (
  xxs: 0,
  // Default
  xs: 360px,
  // Extra small screen / phone
  sm: 640px,
  // Small screen / phone
  md: 768px,
  // Medium screen / tablet
  lg: 1024px,
  // Large screen / desktop
  xl: 1308px,
  //1280px, // Extra large screen / wide screen
  xxl: 1920px // Extra Extra large screen / wide screen,,,,,,,
);
/**
	popup settings
 */
$popup-vw-max-width: 90;
$popup-screen-sm: (
  map-get($grid-breakpoints, sm) * math.div(100, $popup-vw-max-width)
);
$popup-screen-md: (
  map-get($grid-breakpoints, md) * math.div(100, $popup-vw-max-width)
);
$popup-screen-lg: (
  map-get($grid-breakpoints, lg) * math.div(100, $popup-vw-max-width)
);
$popup-screen-xl: (
  map-get($grid-breakpoints, xl) * math.div(100, $popup-vw-max-width)
);

@import "src/style/variables";
@import "src/style/mixins";

.NewProjectWrapper{
    margin-top: 0;
    // max-width: 1164px;
    max-width: 1272px;
    padding-top: 200px;
    // padding-left: 0;
    margin: auto;
    .NewProject{
        height: 571px;
        // padding: 0;
        >div, .StepsContainer{
            box-sizing: border-box;
            height: 100%;
            width: 100%;
        }
    }
    .StepsNavigate{
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &.single-button{
            justify-content: center;
        }
        > div{
            width: auto;
        }
        &.ProjectSum{
            justify-content: center;
            @include desktopFhd{
                .illustration{
                    width: clampFhd(120, 200);
                }
            }
            @include desktopHd{
                .illustration{
                    width: 120px;
                }
            }
        }
    }
}
@include desktopFhd{
    .NewProjectWrapper{
        // max-width: clampFhd(776, 1164);
        max-width: clampFhd(776, 1272);
        padding-top: clampFhd(130, 200);
        .NewProject{
            height: clampFhd(364, 571);
        }
    }
}
.ProjectNewCompetitors{
    .counter{
        font-weight: 600;
    }
    .illustration{
        top: 73px;
    }
}
.NewProjectSummary{
    padding: 90px 108px 0;
    height: calc(100% - 78px - 48px);
    p{
        margin: 4px 0;
        @include desktopFhd{
            margin: clampFhd(0, 4) 0;
        }
    }
    h5{
        margin-bottom: 38px;
        @include desktopFhd{
            margin-bottom: clampFhd(24, 38);
        }
    }
    hr{
        margin: 12px 0;
        height: 0;
        border: none;
        border-bottom: 1px solid #EFECFA;
        @include desktopFhd{
            margin: clampFhd(8, 12) 0;
        }
    }
    b{
        display: block;
    }
    .col__right{
        text-align: right;
        text-align-last: right;
        font-weight: 600;
    }
    .sum{
        font-size: 24px;
        line-height: 36px;
    }
    .summary-grid{
        row-gap: 0;
    }
    .project-sum{
        position: relative
    }
    .project-sum > img.illustration{
        position: absolute;
    }
    .project-new-summary .btn-explain{
        position: unset;
    }
    .create{
        margin-top: 64px;
        @include p__center;
        @include desktopFhd{
            margin-top: clampFhd(40, 48);
        }
    }


    @include desktopFhd{
        padding: clampFhd(57, 90) clampFhd(72, 108) 0;
    }
    @include desktopHd{
        padding: 57px 72px 0;
    }
}
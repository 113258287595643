@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.card{
    border-radius: 32px;
    background-color: $white;
    overflow: hidden;
    &__radiussmall{
        border-radius: 16px;
    }
}
.cardExplain{
    position: relative;
    transition: height .3s ease;
    cursor: pointer;

    .card_btn{
        position: absolute;
        right: 48px;
        top: 52px;
        transition: transform .3s ease;
        width: 12px;
        height: 12px;
    }
    .card_more{
        display: none;
    }
    &.cardExpanded{
        .card_btn{
            transform: rotate(180deg);
        }
        .card_more{
            display: block;
        }
    }
}
@include desktopFhd{
    .cardExplain{
        .card_btn{
            right: clampFhd(32, 48);
            top: clampFhd(32, 48);
        }
    }
}
.card__paddingnormal{
    padding: 48px;
    width: calc(100% - 96px);
}
.card__paddingnarrow{
    padding: 32px;
    width: calc(100% - 64px);
}
.card__paddingsmall{
    padding: 24px;
    width: calc(100% - 48px);
}
.card__paddingminimal{
    padding: 16px;
    width: calc(100% - 32px);
}
.card__paddingnone{
    padding: 0;
    width: 100%;
}
// stíny
.card__shadownormal{
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.0588235);
}
.card__shadownone{
    box-shadow: none;
}
.card_reportBug, .card_explain{
    position: absolute;
    top: 26px;
    right: 26px;
    z-index: 2;
}
.card_reportBug{
    transform: rotate(180deg);
}
.relative{
    position: relative;
}
.card__bordernone{
    border: 0px solid none;
}
.card__borderwhite{
    border: 1px solid white;
}
.card__borderprimary{
    border: 1px solid $primary;
}
.card__borderauxilary{
    border: 1px solid $auxilary;
}
.card__borderauxilaryDashed{
    border: 1px dashed #EFECFA
}
.card__ofvisible{
    overflow: visible;
}
.card__ofscrollX{
    overflow-x: scroll;
}
@include desktopFhd{
    .card__paddingnormal{
        padding: clampFhd(32, 48);
        width: calc(100% - clampFhd(64, 96));
    }
    .card__paddingnarrow{
        padding: vwFhd(32);
        width: calc(100% - vwFhd(64));
    }
}
@include desktopHd{
    .card__paddingnormal{
        padding: 32px;
        width: calc(100% - 64px);
    }
    .card__paddingnarrow{
        padding: 24px;
        width: calc(100% - 48px);
    }
    .cardExplain{
        .card_btn{
            right: 32px;
            top: 42px;
            padding: 5;
            border-width: 0px;
            width: 10px;
            height: 10px;
        }
    }
    .card_explain{
        width: 24px;
        height: 24px;
        top: 24px;
        right: 24px;
        >svg{
            width: 12px;
            height: 12px;
        }
    }
}
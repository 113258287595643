.loader{
    overflow: hidden;
    background-color: rgba(0, 0, 0, .1);
    width: 100%;
    height: 100%;
    cursor: progress;
  }
  .loader-full{
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .loader-inner{
    width: 200px;
    height: 200px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: auto;
  }
  .loader-full > .loader-inner{
    margin-top: 45vh;
  }
  .loader-inner > img{
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    width: 100%;
    height: 100%;
  }
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform:rotate(0deg); } 
    80% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } 
    100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } 
  }
  .loader-disabled{
    display: none;
  }
  @keyframes placeHolderShimmer{
    0%{
        background-position: -100% 0
    }
    100%{
        background-position: 200% 0
    }
}
@keyframes textLoaderAnimation{
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(200%, 0, 0);
    }
}
.text-loader{
    width: 100%;
    height: 1.5em;
    background-color: #d9dce2;
    overflow: hidden;
}
.text-loader::before{
    content: " ";
    height: 2em;
    display: block;
    width: 100%;
    -webkit-animation: textLoaderAnimation 1s linear infinite;
    animation: textLoaderAnimation 1s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}
.text-loader-big{
  height: 100%;
}
.text-loader-big::before{
  height: 100%;
}
.loader-infinity{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
  z-index: 10;
  background-color: rgba(0,0,0,0.1);
}
.loader-infinity > .loader-infinity-inner{
  padding: 64px 64px 48px;
  grid-row: 2;
  grid-column: 2;
  width: 712px;
  min-height: 528px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
}
.loader-infinity > .loader-infinity-inner > .loader-header{
  font: normal normal 700 32px/48px Poppins;
  text-align-last: center;
  text-align: center;
  width: 100%;
  display: block;
  color: #2D0E6B;
}
.loader-infinity > .loader-infinity-inner > .loader-inner{
  width: 100%;
  margin-top: 163px;
  height: auto;
}
.loader-infinity > .loader-infinity-inner > .loader-inner > img{
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
  width: 456px;
  height: auto;
  margin: auto;
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    
}
@import 'src/style/mixins.scss';
.modal{
    overflow: hidden;
    background: rgba(239, 236, 250, 0.5);
    backdrop-filter: blur(12px);
    transition: all .3s ease;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &__aligncenter{
        .heading{
            justify-content: center;
            // @include p__center;
        }
    }
}
.modal__full{
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
.modal__full > .modalInner{
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.0588235);
    border-radius: 32px;
    width: 624px;
    padding: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
}
.modal__dismissable{
    cursor: pointer;
}
.modalInner{
    position: relative;
    > .heading{
        @include p__center;
        margin-bottom: 16px;
    }
    > .info{
        @include p__center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: .3em;
        font-weight: 600;
        margin-bottom: 32px;
        @include p__small;
        >*{
            font-weight: 600;
            @include p__small;
        }
        > span:not(:last-child)::after{
            content: " |";
        }
    }
    > .content{
        text-align: justify;
        text-align-last: center;
        *{
            text-align: justify;
            text-align-last: center;
        }
    }
    .close{
        position: absolute;
        top: -12px;
        right: -12px;
        // transform: translate(50%, -50%);
    }
}
.modal__disabled{
    display: none;
}
.modal.modal__small{
    .modalInner{
        width: 360px;
        padding: 32px 64px;
        overflow: visible;
    }
    .modal_heading{
        @include h6;
        margin-bottom: 32px;
    }
}
@include desktopFhd{
    .modal__full > .modalInner{
        border-radius: vwFhd(32);
        width: vwFhd(624);
        padding: vwFhd(48);
    }
    .modalInner{
        > .heading{
            margin-bottom: vwFhd(16);
        }
        > .info{
            margin-bottom: vwFhd(32);
        }
        .close{
            position: absolute;
            top: -vwFhd(12);
            right: -vwFhd(12);
        }
    }
    .modal.modal__small{
        .modalInner{
            width: vwFhd(360);
            padding: vwFhd(32) vwFhd(64);
        }
        .modal_heading{
            margin-bottom: vwFhd(32);
        }
    }
    .modal.modal__high{
        .modalInner{
            width: vwFhd(1272);
            padding: vwFhd(32) vwFhd(64);
        }
        .modal_heading{
            margin-bottom: vwFhd(32);
        }
    }
}
@include desktop{

}
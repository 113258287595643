@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}
.Spinner{
    display: inline-block;
    width: .5em;
    height: .5em;
    vertical-align: -0.125em;
    border: 0.25em solid var(--spinner-color);
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spin;
    animation: .75s linear infinite spin;
}
@import './variables';
@import './mixins';
@import './scroll';
@import './DatePicker.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

// @property --svg-color-primary {
//   syntax: "<color>";
//   initial-value: $primary;
//   inherits: false;
// }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --svg-color-primary: #{$primary};
  --spinner-color: #{$primary};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2d0e6b;
  margin: 0;
  padding: 0;
}
h1,
.h1 {
  @include h1;
}
h2,
.h2 {
  @include h2;
}
h3,
.h3 {
  @include h3;
}
h4,
.h4 {
  @include h4;
}
h5,
.h5 {
  @include h5;
}
h6,
.h6 {
  @include h6;
}
hr {
  border: 0px solid $auxilary;
  border-bottom: 1px solid $auxilary;
  border-left: 1px solid $auxilary;
}
p,
a,
.p,
li,
span,
div,
body,
input {
  font-family: Poppins;
  color: #2d0e6b;
  font-style: normal;
  @include p;
}
b {
  font-weight: 600;
}
.app-root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.main {
  margin: unset;
  margin-top: 84px;
  margin-left: 432px;
  padding-left: 84px;
  border-radius: 48px 0px 0px 48px;
  padding-bottom: 64px;
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 84px - 64px);
  width: calc(100vw - 432px - 84px);
  overflow: hidden scroll;
  background-color: white;
  @include desktopFhd {
    // padding-left: vwFhd(516);
    // padding-top: vwFhd(84);
    // padding-bottom: vwFhd(64);
    margin-left: clampFhd(288, 432);
    margin-top: clampFhd(56, 84);
    padding-bottom: clampFhd(32, 64);
    padding-left: clampFhd(50, 84);
    width: calc(100vw - clampFhd(338, 432) - clampFhd(50, 84));
  }
  @include desktopHd {
    margin-left: 288px;
    margin-top: 56px;
    padding-left: 50px;
    padding-bottom: 32px;

    height: calc(100vh - 64px);
    width: calc(100vw - 288px - 50px);
    // padding-left: 338px;
    // padding-top: 56px;
    // padding-bottom: 32px;
  }
  &.main-wide-centered {
    padding-left: 30px;
    > * {
      width: calc(100vw - 432px - 84px);
    }
    @include desktopFhd {
      padding-left: vwFhd(30);
    }
    @include desktopHd {
      padding-left: 24px;
    }
  }
  &.main-wide {
    margin-left: 114px;
    width: calc(100vw - 114px * 2);
    > * {
      margin: auto;
    }
  }
}
.appShadow {
  position: fixed;
  top: 84px;
  left: 84px;
  width: calc(100vw - 84px);
  height: calc(100vh - 84px);
  box-shadow: -4px 0px 40px rgba(0, 0, 0, 0.03);
  z-index: 3;
  pointer-events: none;
  border-radius: 48px 0 0 48px;
  @include desktopFhd {
    top: vwFhd(84);
    left: vwFhd(84);
    width: calc(100vw - vwFhd(84));
    height: calc(100vh - vwFhd(84));
  }
  @include desktopHd {
    top: 56px;
    left: 56px;
    width: calc(100vw - 56px);
    height: calc(100vh - 56px);
  }
}
@media print {
  .main {
    margin: 0;
    width: 100%;
    padding: 0;
  }
  .appShadow {
    display: none;
  }
}
input,
.input {
  @include input;
}
.input-ok {
  //deprecated
  background: #ecfeea;
  border: 1px solid #67df42;
  position: relative;
}
input[type='checkbox']:checked + .slider {
  background-color: #2d0e6b;
}

input[type='checkbox']:focus + .slider {
  box-shadow: 0 0 1px #d8ccf8;
}

input[type='checkbox']:checked + .slider:before {
  border-color: #2d0e6b;
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
input.input-underline {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  border: 0px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid #efecfa;
}
label {
  font-family: Poppins;
  font-style: normal;
  // font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #2d0e6b;
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
}
input[type='radio'],
input[type='checkbox'] {
  all: unset;
  background: #efecfa;
  border: 1px solid #2d0e6b;
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  margin-right: 12px;
  vertical-align: middle;
  padding: 1px;
  position: relative;
}
input[type='checkbox'] {
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}
input[type='radio']:hover,
input[type='checkbox']:hover {
  border-width: 2px;
  padding: 0;
}
input[type='radio']::after,
input[type='checkbox']::after {
  position: absolute;
  content: '';
}
input[type='radio']::after {
  @include centered;
  background-color: #efecfa;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  // left: 3px;
  // top: 3px;
  transition: background-color 0.3s ease-in-out;
}
strong,
b {
  font-weight: 600;
}
input[type='radio']:checked::after {
  background-color: #2d0e6b;
}
input[type='checkbox']:checked::after {
  opacity: 1;
}
input[type='checkbox']:checked {
  background-color: #2d0e6b;
}
input[type='checkbox']::after {
  top: 0px;
  left: 3px;
  width: 14px;
  height: 14px;
  // content: url("/svgs/status/Check.svg");
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
input[type='radio']:hover::after {
  // top: 3px;
  // left: 3px;
}
input[type='checkbox']:hover::after {
  top: -1px;
  left: 2px;
}
// @keyframes spin {
//   from {
//       transform: rotate(0deg);
//   } to {
//       transform: rotate(360deg);
//   }
// }
// :global {
//   .spinner {
//       & :local {
//         -webkit-animation: .75s linear infinite spin;
//         animation: .75s linear infinite spin;
//       }
//     }
//   }
//   .spinner{
//   display: inline-block;
//   width: 1em;
//   height: 1em;
//   vertical-align: -0.125em;
//   border: 0.25em solid white;
//   border-right-color: transparent;
//   border-radius: 50%;
//   -webkit-animation: .75s linear infinite spin;
//   animation: .75s linear infinite spin;
// }
@include desktopHd {
}

@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.TabsVertical{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    .Tab{
        cursor: pointer;
        color: $primary100;
        font-weight: 600;
        position: relative;
        padding: 0;
        &.Tab__active{
            color: $primary;
        }
        &:not(:last-child)::after{
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $auxilary;
            position: absolute;
            bottom: -16px;
        }
    }
}
@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.btn{
    @include p;
    all: unset;
    text-decoration: none;
    cursor: pointer;
    // display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid $primary;
    padding: 6px 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    transition: all .3s ease-in-out;
    column-gap: 12px;
}
.spinner{
    @include spinner;
}
button:disabled, button:disabled:hover, .btn__disabled{
    cursor: not-allowed;
}
.btn__primary{
    background-color: $primary;
    color: $white;
    --spinner-color: #{$white};
    &:hover{
        background-color: $auxilary;
        color: $primary;
        --spinner-color: #{$primary};
    }
    &:disabled, &:disabled:hover, &.btn__disabled{
        background-color: $grey300;
        cursor: not-allowed;
        border-color: $grey300;
        color: #fff;
    }
}
.btn__secondary{
    color: $primary;
    background-color: none;

    &:hover{
        border-color: $auxilary;
        background-color: none;
    }
    &:disabled, &:disabled:hover{
        color: $grey300;
        border-color: $grey300;
    }
}
.btn__terciary{
    border: none;
    padding: 0;
    color: $primary;

    &:hover{
        text-decoration: underline;
        background-color: unset;
        color: $primary;
    }
}
.btn__tertiary{
    border: none;
    background-color: none;
    padding: 4px 12px;

    &:hover{
        background-color: $auxilary;
    }
}
.btn__cta{
    background-color: $secondary;
    color: $white;
    border-color: $secondary;
    svg{
        --svg-color-primary: #fff;
    }
    &:hover{
        background-color: $secondary100;
        border-color: $secondary;
        color: $secondary;
        svg{
            --svg-color-primary: #{$secondary};
        }
    }
    &:disabled, &:disabled:hover{
        background-color: $grey300;
        border-color: $grey300;
        color: #fff;
    }
}
.btn__table-click-through{
    border: none;
    padding: 0;
    font-weight: normal;
    transition: all .3s ease;
    &:hover{
        font-weight: 600;
    }
}

.btn__small{
    padding: 8px 24px;
    @include p__small;
}
.btn__hasIcon > svg{
    width: 16px;
    height: 16px;
    vertical-align: middle;
    align-self: center;
}
.btn__icon{
    padding: 12px;
    line-height: 16px;
    height: 16px;

    &.btn__secondary{
        padding: 8px;
        background-color: $auxilary;
        border-color: $auxilary;

        &:hover{
            border-color: $primary;
        }
    }
    
    > svg{
        width: 16px;
        height: 16px;
        // filter: brightness(0) invert(13%) sepia(67%) saturate(2854%) hue-rotate(250deg) brightness(82%) contrast(112%);
    }
    &.btn__small{
        padding: 6px;
        svg{
            width: 8px;
            height: 8px;
        }
    }
    &.btn__big{
        svg{
            width: 40px;
            height: 40px;
        }
    }
}
.btn__tag{
    // position: relative;
    // @include p__small;
    // padding: 6px 14px;
    // border-color: $auxilary;
    // height: 18px;
    // &:hover{
    //     border-color: $primary;
    // }
}
.btn__tag-active{
    // padding: 8px 32px 8px 16px;
    // height: 21px;
    // position: relative;
    // background-color: $auxilary;
    // border-color: $auxilary;
    // &:hover{
    //     background-color: white;
    // }
    // >div{
    //     position: absolute;
    //     width: 16px;
    //     height: 16px;
    //     border-radius: 50%;
    //     background-color: $primary;
    //     right: 8px;
    //     top: 50%;
    //     transform: translate(0, -50%);
    //     >svg{
    //         position: absolute;
    //         width: 5px;
    //         height: 5px;
    //         @include centered;
    //     }
    // }
}
.btn__icon-hover{
    border: none;
    display: grid;
    padding: 0;
    >svg{
        width: 24px;
        height: 24px;
        opacity: 1;
        transition: opacity .3s ease;
        grid-column: 1;
        grid-row: 1;
        @include desktopFhd{
            width: vwFhd(24);
            height: vwFhd(24);
        }
    }
    >svg:last-child{
        opacity: 0;
    }
    &:hover{
        background-color: $transparent;
        >svg:first-child{
            opacity: 0;
        }
        >svg:last-child{
            opacity: 1;
        }
    }
    &:disabled, &.btn__disabled{
        --svg-color-primary: #{$primary100};
        cursor: not-allowed;
    }
}
.btn__none{
    all: unset;
}
// .btn__iconBefore > svg{
//     margin-right: 12px;
// }
// .btn__iconAfter > svg{
//     margin-left: 12px;
// }
// .btn__small.btn__iconBefore > svg{
//     margin-right: 8px;
// }
// .btn__small.btn__iconAfter > svg{
//     margin-left: 8px;
// }
.btn__iconAfter svg{
    height: 1em;
    width: 1em;
}
.btn__offsetLeftnormal{
    margin-left: 16px;
}
.btn__weightlight{
    font-weight: 400;
}
.btn__link{
    background-color: unset;
    padding: 0;
    border: none;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
}

@include desktopFhd{
    .btn{
        padding: 6px clamp(22px, vwFhd(30), 30px);
        column-gap: clamp(8px, vwFhd(12), 12px);
        &__none{
            padding: 0;
        }
    }
    .btn__tag{
        padding: 8px 16px;
        border-color: $auxilary;
        height: 21px;
    }
    .btn__tag-active{
        padding: 8px 32px 8px 16px;
        height: 21px;
        position: relative;
        background-color: $auxilary;
        border-color: $auxilary;
        &:hover{
            background-color: white;
        }
        >div{
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: $primary;
            right: 8px;
            top: 50%;
            transform: translate(0, -50%);
            >svg{
                position: absolute;
                width: 5px;
                height: 5px;
                @include centered;
            }
        }
    }
    .btn__icon-hover, .btn__tertiary{
        padding: 0;
    }
    // .btn{
    //     border-width: vwFhd(1);
    //     padding: 6px clamp(22px, vwFhd(30), 30px);
    //     

    //     padding: 6px 22px;
    //     column-gap: 8px;
    // }
    .btn__link{
        padding: 0;
    }
}
@include desktopHd{
    .btn{
        padding: 6px 22px;
        column-gap: 8px;
        &__none{
            padding: 0;
        }
    }
    .btn__tag, .btn__tag-active{
        padding: 7px 15px 7px 15px;
    }
    .btn__tag-active{
        padding-right: 31px;
    }
    .btn__none{
        all: unset;
    }
    .IconBtn, .IconBtnLink{
        padding: 0;
    }
    .btn__icon-hover, .btn__tertiary{
        padding: 0;
    }
    .btn__link{
        padding: 0;
    }
}
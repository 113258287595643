@use "sass:math";
@import 'src/style/mixins.scss';

.container{
    max-width: 1272px;
    padding: 0 24px;
}
.main-content-wide > .container{
  max-width: 1672px;
  padding: 0 24px;
  > .grid{
    grid-template-columns: repeat(17, 1fr);
  }
}
.containerFluid{
  width: 100%;
  max-width: 100%;
  > .row{
    flex-wrap: nowrap;
  }
}
.gap{
  padding: 24px;
  width: calc(100% - 48px);
}
.row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -24px;
    margin-left: -24px;
    padding: 0 24px;
    column-gap: 24px;
    .col12, .col11, .col10, .col9, .col8, .col7, .col6, .col5, .col4, .col3, .col2, .col1{
        width: 100%;
    }
    .col{
        flex: 1 0 0%
      }
      .col1{
        width: calc(100% / 12);
      }
      .col2{
        width: calc(100% / 6);
      }
      .col3{
        width: calc(100% / 4);
      }
      .col4{
        width: calc(100% / 3);
      }
      .col5{
        width: calc(100% / 12 * 5);
      }
      .col6{
        width: calc(100% / 2);
      }
      .col7{
        width: calc(100% / 12 * 7);
      }
      .col8{
        width: calc(100% / 12 * 8);
      }
      .col9{
        width: calc(100% / 12 * 9);
      }
      .col10{
        width: calc(100% / 12 * 10);
      }
      .col11{
        width: calc(100% / 12 * 11);
      }
      .col12{
        width: 100%;
      }
}
.grid{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;


    .col12, .col11, .col10, .col9, .col8, .col7, .col6, .col5, .col4, .col3, .col2, .col1{
        width: 100%;
    }
    .col12{
        grid-column: span 12;
      }
      .col11{
        grid-column: span 11;
      }
      .col10{
        grid-column: span 10;
      }
      .col9{
        grid-column: span 9;
      }
      .col8{
        grid-column: span 8;
      }
      .col7{
        grid-column: span 7;
      }
      .col6{
        grid-column: span 6;
      }
      .col5{
        grid-column: span 5;
      }
      .col4{
        grid-column: span 4;
      }
      .col3{
        grid-column: span 3;
      }
      .col2{
        grid-column: span 2;
      }
      .col1{
        grid-column: span 1;
      }
}
.marginReset{
    margin: 0;
}
// .offsetSection{
//     margin-top: 64px;
// }
// .offsetBigger{
//   margin-top: 48px;
// }
// .offset{
//     margin-top: 40px;
// }
// .offsetBig{
//   margin-top: 32px;
// }
// .offsetMedium{
//     margin-top: 24px;
// }
// .offsetSmall{
//      margin-top: 20px;
// }
// .offsetNarrow{
//     margin-top: 16px;
// }
// .offsetMinimal{
//     margin-top: 8px;
// }
.offsetEven{

}
.offsetEven__big{
  margin-top: 40px;
}
.offsetEven__mid{
  margin-top: 20px;
}
.offset__huge{
  margin-top: 64px;
}
.offset__big{
  margin-top: 48px;
}
.offset, .offset__normal{
  margin-top: 32px;
}
.offset__mid{
  margin-top: 24px;
}
.offset__small{
  margin-top: 16px;
}
.offset__minimal{
  margin-top: 8px;
}
.marginBottom__huge{
  margin-bottom: 64px;
}
.marginBottom__big{
  margin-bottom: 48px;
}
.marginBottom, .marginBottom__normal{
  margin-bottom: 32px;
}
.marginBottom__mid{
  margin-bottom: 24px;
}
.marginBottom__small{
  margin-bottom: 16px;
}
.marginBottom__minimal{
  margin-bottom: 8px;
}
.container__xscroll{
  overflow-x: scroll;
}
.scrollbarHidden{
  @include scrollbarHidden;
}
@include desktopFhd{
  .row{
    column-gap: vwFhd(24);
  }
  .offset__mid{
    margin-top: vwFhd(16);
  }
  .offset, .offset__normal{
    margin-top: clampFhd(24, 32);
  }
  .offset__huge{
    margin-top: vwFhd(48);
  }
  .marginBottom__huge{
    margin-bottom: clampFhd(48, 64);
  }
  .marginBottom__big{
    margin-bottom: clampFhd(32, 48);
  }
  .marginBottom__mid{
    margin-bottom: clampFhd(16, 24);
  }
  // .marginBottom__small{
  //   margin-bottom: clampFhd(16, 24);
  // }
}
@include desktopHd{
  .gap{
    
  }
  .row{
    padding: 0 22px;
    column-gap: 16px;
  }
  .offset__mid{
    margin-top: 16px;
  }
  .offset__huge{
    margin-top: 48px;
  }
  .marginBottom__huge{
    margin-bottom: 48px;
  }
  .marginBottom__big{
    margin-bottom: 32px;
  }
  .marginBottom__mid{
    margin-bottom: 16px;
  }
}
@import "src/style/variables.scss";
@import "src/style/mixins.scss";

.drop-file{
    display: none;
}
.drop-heading{
    margin-bottom: 46px;
    @include desktopFhd{
        margin-bottom: clampFhd(32, 48);
    }
}
.drop-file-name{
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: clampFhd(16, 24);
    /* padding: 46px 0 16px; */
}
.drop-filetypes{
    margin-top: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
.drop-file-inner{
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 406px;
    @include desktopFhd{
        max-width: clampFhd(272, 408);
    }
    @include desktopHd{
        max-width: 272px;
    }
}
.drop-file-inner > .input-ok{
    padding-right: 32px;
    text-align-last: left;
    width: calc(100% - 47px);
    text-align: left;
}
.drop-file-inner > img{
    position: absolute;
    right: 8px;
    top: 10px;
    width: 16px;
    height: 16px;
    /* opacity: 0.2; */
    /* margin-left: -24px; */
}
.drop-out{
    position: relative;
}
.drop-catch-area{
    position: absolute;
    width: 100%;
    height: 0;
}
.drop-out.drag-over-active{
    background-color: #2D0E6B;
}
.drop-out.drag-over-active .drop-catch-area{
    background-color: rgba(0,0,0,0.1);
    left: 0;
    top: 0;
    height: 100%;
    z-index: 2;
}
.drop-out.drag-over-active > *:not(.drop-heading):not(.drop-catch-area){
    opacity: 0;
}
.drop-out.drag-over-active .drop-heading{
    color: white;
    opacity: 1;
}
.drop-out hr{
    border: 1px solid #EFECFA;
}
.drop-filetypes{
    /* display: block; */
}
.drop-file-out > .toggle{
    margin-bottom: 48px;
}
.drop-file-label{
    // margin-top: 24px;
}
/* .drop-out{
    background-color: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    overflow: hidden;

}
.drop-area{
    padding: 40px 96px;
    text-align: center;
}
.drop-heading{
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #1C1C1E;
    text-align: center;
    text-align-last: center;
    margin: 0 86px 24px;
}
.drop-heading-for{
    display: inline;
    font-weight: 700;
    color: #2D0E6B;
    margin-left: .3em;
    text-transform: uppercase;
}
.drop-file[type="file"]{
    display: none;
}
.drop-file-label, .drop-file-name{
    padding: 9px 10px;
    background-color: #2D0E6B;
    border-radius: 10px;
    display: block;
    cursor: pointer;
    position: relative;
    text-align: center;
    font: normal normal bold 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    text-transform: uppercase;
}
.drop-file-label{
    padding: 14px 10px;
    margin: 0 86px;
}
.drop-file-label > img{
    position: absolute;
    left: 0;
    top: 0;
    background: #2D0E6B 0% 0% no-repeat padding-box;
    border-radius: 9px;
    padding: 6px 8px;
    margin: 4px;
}
.drop-file-name{
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: 48px auto 48px;
    grid-template-rows: 1fr;
    background-color: #ECFEEA;
    box-shadow: 0px 0px 16px #0000000D;
    padding: 0;
}
.drop-file-name > *{
    grid-column: span 1;
    margin: 0;
    width: 100%;
}
.drop-file-name > input{
    padding: 0 10px;
    background-color: inherit;
    font: normal normal 700 14px/21px Poppins;
    height: 48px;
    width: calc(100% - 20px);
    color: #12263C;
    border: none;
}
.drop-file-name > img, .drop-file-name > .drop-remove > img{
    width: 18px;
    height: 12px;
    padding: 18px 15px;
    background-color: #67DF42;
    border-radius: 8px;
}
.drop-file-name > .drop-remove > img{
    background-color: inherit;
    width: 16px;
    height: 16px;
    padding: 16px;
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
}
.drop-file-name > .drop-remove{
    width: 32px;
    height: 32px;
}
.drop-file-label.hidden{
    display: none;
}
.drop-filetypes{
    display: block;
    margin-top: 16px;
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0px;
    color: #B4B4B4;
}
.drop-filetype{
    margin-left: .2em;
}
.drop-out-disabled .drop-file-label{
}
.drop-out-disabled .drop-file-label img{
    background: #12263C 0% 0% no-repeat padding-box;
}
.drop-area > .drop-catch-area{
    display: none;
}
.drag-over{
    padding: 0;
    background-color: #190d35;
    height: 300px;
    position: relative;
}
.drag-over > *:not(.drop-catch-area):not(.drop-heading){
    display: none;
}
.drag-over > .drop-heading{
    color: white;
    position: absolute;
    top: 100px;
    margin: 25px 108px;
    z-index: 1;
}
.drag-over > .drop-heading > .drop-heading-for{
    color: white;
}
.drag-over > .drop-catch-area{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0);
}
@media (max-width: 1919px){
    .drop-area{
        padding: 24px 72px;
    }
    .drop-area.drag-over{
        height: 200px;
        padding: 0;
    }
    .drag-over > .drop-heading{
        top: 50px
    }
    .drop-heading{
        font: normal normal 600 12px/18px Poppins;
        margin: 3px 0 24px;
    }
    .drop-file-label, .drop-file-name, .drop-file-name > input{
        font: normal normal bold 10px/16px Poppins;
    }
    .drop-file-label{
        margin: 0;
        padding: 9px;
    }
    .drop-filetypes{
        margin-top: 8px;
        font: normal normal 600 8px/12px Poppins;
    }
    .drop-file-name{
        grid-template-columns: 32px auto 32px;
    }
    .drop-file-name > img, .drop-file-name > .drop-remove > img{
        width: 12px;
        height: 8px;
        padding: 13px 10px;
    }
    .drop-file-name > input{
        height: 32px;
    }
} */
@import "src/style/variables";
@import "src/style/mixins";

.Steps{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    &::after{
        // content: "";
        // display: block;
        // position: absolute;
        // width: 1px;
        // // box-sizing: content-box;
        // height: 100%;
        // z-index: 1;
        // background-color: $auxilary;
        // left: 16px;
        // // top: 32px;
    }
    .step{
        display: grid;
        align-items: center;
        grid-template-columns: 32px auto 16px;
        column-gap: 24px;
        position: relative;
        z-index: 2;
        .step_line{
            position: absolute;
            width: 1px;
            background-color: $auxilary;
            left: 16px;
            top: 16px;
            z-index: 1;
        }
        &__done:not(.step__current){
            .step_line{
                background-color: $primary;
            }
        }
        &:last-of-type{
            .step_line{
                display: none;
            }
        }
        .step_number{
            z-index: 2;
            display: inline-block;
            background-color: white;
            width: 30px;
            height: 30px;
            text-align: center;
            border: 1px solid $auxilary;
            border-radius: 50%;
            position: relative;
            p{
                position: absolute;
                margin: 0;
                top: 50%;
                left: 50%;
                display: inline-block;
                transform: translate(-50%, -50%);
            }
        }
        .step_name{
            line-height: 32px;
            margin: 0;
            display: inline-block;
        }
        &.step__current, &.step__done{
            p{
                font-weight: 600;
            }
            .step_number{
                border-color: $primary;
            }
        }
        --svg-color-primary: #{$success};
        &.step__done{
            .step_number{
                background-color: $primary;
                p{
                    color: white;
                }
            }
            &:not(:last-child)::after{
                background-color: $primary;
            }
        }
        &.step__current{
            .step_number{
                background-color: white;
                p{
                    color: $primary;
                }
            }
        }
        &.step__skipped{
            .step_status{
                width: 13px;
                height: 13px;
                margin: 3.5px;
            }
            --svg-color-primary: #{$error};
        }
        .step_status{
            width: 20px;
            height: 20px;
            margin: 0;
            align-self: center;
        }
    }
}
.steps > .step.step__done .step_status{
    /* zelená fajfka */
    margin-top: 11px;
    // filter: invert(78%) sepia(33%) saturate(961%) hue-rotate(52deg) brightness(95%) contrast(88%);
}
.steps > .step.step__skipped .step_status{
    /* červený křížek */
    margin-top: 10px;
    width: 10px;
    // filter: invert(34%) sepia(47%) saturate(2423%) hue-rotate(329deg) brightness(95%) contrast(82%);
}
.step.step__clickable{
    cursor: pointer;
}
@include desktopFhd{
    .Steps{
        .step{
            grid-template-columns: clampFhd(24, 32) auto clampFhd(8, 16);
            column-gap: clampFhd(16, 24);
            .step_line{
                left: clampFhd(12, 16);
                top: clampFhd(12, 16);
            }
            .step_number{
                z-index: 2;
                display: inline-block;
                background-color: white;
                width: clampFhd(22, 30);
                height: clampFhd(22, 30);
                p{
                    @include p__small;
                }
            }
            .step_name{
                @include p__small;
                line-height: clampFhd(24, 32);
            }
            &.step__skipped{
                .step_status{
                    width: clampFhd(10.4, 13);
                    height: clampFhd(10.4, 13);
                    margin: 3.5px;
                }
                --svg-color-primary: #{$error};
            }
            .step_status{
                width: clampFhd(16, 20);
                height: clampFhd(16, 20);
                margin: 0;
                align-self: center;
            }
        }
    }
}
@include desktopHd{
    .Steps{
        .step{
            grid-template-columns: 24px auto 8px;
            column-gap: 16px;
            .step_line{
                left: 12px;
                top: 12px;
            }
            .step_number{
                z-index: 2px;
                display: inline-block;
                background-color: white;
                width: 22px;
                height: 22px;
                p{
                    @include p__small;
                }
            }
            .step_name{
                @include p__small;
                line-height: 24;
            }
            &.step__skipped{
                .step_status{
                    width: 10.4px;
                    height: 10.4px;
                    margin: 3.5px;
                }
                --svg-color-primary: #{$error};
            }
            .step_status{
                width: 16px;
                height: 16px;
                margin: 0;
                align-self: center;
            }
        }
    }
}
@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.IconBtn,
.IconBtnLink {
  all: unset;
  cursor: pointer;
  position: relative;
  padding: 0;
  border-radius: 50%;
  display: inline-flex;
  border: 1px solid $primary;
  justify-content: center;
  align-items: center;
  //   > svg {
  //     @include centered;
  //   }
  .notification {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    background-color: $error;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    > span {
      @include p__xsmall;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.IconBtn__dark {
  background-color: $primary;
  border-color: $primary;
  --svg-color-primary: #fff;
  &:hover {
    background-color: $auxilary;
    --svg-color-primary: #{$primary};
  }
}
.IconBtn__light {
  border-color: $auxilary;
  background-color: $auxilary;
  --svg-color-primary: #{$primary};
  &:hover {
    border-color: $primary;
  }
}
.IconBtn__transparent {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  --svg-color-primary: #{$primary};
  &:hover {
    border-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
  }
}
.IconBtn__size20 {
  width: 18px;
  height: 18px;
  > svg {
    width: 10px;
    height: 10px;
  }
}
.IconBtn__size24 {
  width: 22px;
  height: 22px;
  > svg {
    width: 10px;
    height: 10px;
  }
}
.IconBtn__size32 {
  width: 30px;
  height: 30px;
  > svg {
    width: 16px;
    height: 16px;
  }
  @include desktopFhd {
    width: vwFhd(30);
    height: vwFhd(30);
    > svg {
      height: vwFhd(16);
      width: vwFhd(16);
    }
  }
  @include desktopHd {
    width: 22px;
    height: 22px;
    > svg {
      width: 10px;
      height: 10px;
    }
  }
}
.IconBtn__size40 {
  width: 40px;
  height: 40px;
  > svg {
    width: 16px;
    height: 16px;
  }
  @include desktopFhd {
    width: vwFhd(40);
    height: vwFhd(40);
    > svg {
      height: vwFhd(16);
      width: vwFhd(16);
    }
  }
  @include desktopHd {
    width: 30px;
    height: 30px;
    > svg {
      width: 14px;
      height: 14px;
    }
  }
}
.IconBtn__size48 {
  width: 46px;
  height: 46px;
  > svg {
    width: 20px;
    height: 20px;
  }
  @include desktopFhd {
    width: vwFhd(46);
    height: vwFhd(46);
    > svg {
      height: vwFhd(20);
      width: vwFhd(20);
    }
  }
  @include desktopHd {
    width: 38px;
    height: 38px;
    > svg {
      width: 16px;
      height: 16px;
    }
  }
}
.IconBtn__size16 {
  width: 16px;
  height: 16px;
  > svg {
    width: 8px;
    height: 8px;
  }
  @include desktopFhd {
    width: vwFhd(16);
    height: vwFhd(16);
    > svg {
      height: vwFhd(8);
      width: vwFhd(8);
    }
  }
  @include desktopHd {
    width: 12px;
    height: 12px;
    > svg {
      width: 6px;
      height: 6px;
    }
  }
}

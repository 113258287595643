@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.HeaderBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 84px;
  width: calc(100vw - 56px);
  background-color: #fff;
  display: grid;
  grid-template-columns: auto 1fr auto;
  // padding: 0 40px 0 28px;
  padding-right: 40px;
  column-gap: 32px;
  z-index: 2;
  .lang {
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.HeaderLogo {
  width: 84px;
  position: relative;
  > svg {
    @include centered;
    height: 32px;
    max-width: 32px;
  }
}
.HeaderMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-left: 424px;
  > p {
    text-align: left;
    text-align-last: left;
  }
}
.HeaderControl {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  height: 40px;
  padding: 22px 0;
  align-items: center;
  > hr {
    border-right: 1px solid $auxilary;
    height: 100%;
    padding: 0;
    margin: 0 16px;
  }
}
.btnProfile {
  // margin-left: 16px;
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-template-rows: 21px 15px;
  column-gap: 15px;
  row-gap: 4px;
  padding: 0;
  background-color: $transparent;
  border: none;
  border-radius: 100px;
  > .profile_img {
    grid-column: 1;
    grid-row: 1 / span 2;
    background-color: $auxilary;
    border-radius: 50%;
    position: relative;
    > img {
      @include centered;
      width: 40px;
      height: 40px;
    }
    > svg {
      @include centered;
      width: 20px;
      height: 20px;
    }
  }
  > .profile_name,
  > .profile_company {
    margin: 0;
    font-weight: 600;
  }
  > .profile_company {
    opacity: 0.5;
    grid-column: 2;
    grid-row: 2;
    font-size: 10px;
    line-height: 15px;
  }
  > .profile_expand {
    grid-row: 1 / span 2;
    position: relative;
    > * {
      @include centered;
      height: 16px;
      width: 16px;
    }
  }
}
@include desktopFhd {
  .HeaderBar {
    height: vwFhd(84);
    width: calc(100vw - vwFhd(40));
    padding-right: vwFhd(40);
    column-gap: vwFhd(32);
  }
  .HeaderMessage {
    // 84 + 432
    //menu - (side-panel + col gap)
    margin-left: vwFhd(424);
  }
  .HeaderLogo {
    width: vwFhd(84);
    padding: 0;
    > svg {
      height: vwFhd(32);
      max-width: vwFhd(32);
    }
  }
  .HeaderControl {
    column-gap: vwFhd(16);
    height: vwFhd(40);
    padding: vwFhd(22) 0;
    > hr {
      margin: 0 vwFhd(16);
    }
  }
  .btnProfile {
    margin-left: vwFhd(16);
    grid-template-columns: vwFhd(40) auto vwFhd(40);
    grid-template-rows: vwFhd(21) vwFhd(15);
    column-gap: vwFhd(15);
    row-gap: vwFhd(4);
    > .profile_img > img {
      width: vwFhd(40);
      height: vwFhd(40);
    }
    > .profile_company {
      font-size: vwFhd(10);
      line-height: vwFhd(15);
    }
    > .profile_expand {
      > * {
        height: vwFhd(16);
        width: vwFhd(16);
      }
    }
  }
}
@include desktopHd {
  .HeaderBar {
    height: 56px;
    padding: 0 24px 0 0;
    column-gap: 24px;
  }
  .HeaderLogo {
    width: 56px;
    position: relative;
    > svg {
      @include centered;
      height: 24px;
      max-width: 24px;
    }
  }
  .HeaderControl {
    height: 32px;
    padding: 12px 0;
    .actionButton {
      padding: 0;
      > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .HeaderMessage {
    margin-left: 280px;
    max-height: 56px;
  }
  .btnProfile {
    grid-template-columns: 32px auto 32px;
    grid-template-rows: 18px 12px;
    row-gap: 0;
    > .profile_img > img {
      width: 32px;
      height: 32px;
    }
    > .profile_name,
    > .profile_company {
      font-size: 12px;
      line-height: 18px;
    }
    > .profile_company {
      font-size: 8px;
      line-height: 12px;
    }
    > .profile_expand > * {
      @include centered;
      height: 12px;
      width: 12px;
    }
  }
}
@media print {
  .HeaderBar {
    display: none;
  }
}

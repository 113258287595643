@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';
.ErrorCritical{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: $auxilary;
    position: relative;
    >.ErrorCritical_center{
        @include centered;
        > img{
            width: 100px;
            animation:spin 4s linear infinite;
        }
    }
    .ErrorCritical_heading{
        position: absolute;
        bottom: 20%;
        width: 100%;
        >*{
            text-align: center;
            text-align-last: center;
        }
        p{
            margin-top: 24px;
        }
    }
}
@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}
@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.Segment{
    all: unset;
    border-radius: 100px;
    cursor: pointer;
    background-color: $transparent;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 15px;
    border: 1px solid $auxilary;
    // height: 18px;
    column-gap: 4px;
    &:hover{
        border-color: $primary;
    }
    >.Segment_label{
        font-weight: 600;
        @include p__small;
    }
}
.Semgent__active{
    background-color: $auxilary;
    padding-right: 7px;
    > .Segment_cross{
        margin-left: 4px;
        width: 14px;
        height: 14px;
        border: 1px solid $primary;
        border-radius: 50%;
        background-color: $primary;
        position: relative;
        &:hover{
            background-color: $auxilary;
            > svg{
                --svg-color-primary: #{$primary};
            }
        }
        >svg{
            @include centered;
            width: 5px;
            height: 5px;
        }
    }
}
@include desktopFhd{
    .Segment{
        padding: clampFhd(5, 7) clampFhd(11, 15);
    }
    .Semgent__active{
        padding-right: clampFhd(5, 7);
    }
}
@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.Menu{
    position: fixed;
    left: 84px;
    top: 84px;
    height: calc(100vh - 84px);
    background: linear-gradient(180deg, $auxilary 0%, rgba(239, 236, 250, 0.25) 100%);
    border-radius: 48px 0px 0px 48px;
    width: 432px;
    &.Menu__narrow{
        width: 108px;
        .Menu_in{
            padding: 0;
            margin-right: 0;
            width: calc(100% - 30px);
        }
        .Menu_heading, .Menu_items{
            opacity: 0;
            display: none;
        }
        .Menu_cover{
            right: 0;
        }
    }
}
.Menu_in{
    position: relative;
    margin: 48px 114px 24px 30px;
    height: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    row-gap: 42px;
}
.Menu_heading{
    padding-left: 34px;
    font-size: 32px;
    line-height: 48px;
    font-weight: bold;
}
.Menu_items{
    >ul{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        align-items: flex-start;
    }
}
.Menu_cover{
    background-color: white;
    width: 84px;
    height: calc(100vh - 84px);
    position: absolute;
    right: -114px;
    top: -48px;
    border-radius: 48px 0px 0px 48px;
}
.Menu_toggle{
    all: unset;
    position: absolute;
    right: -30px;
    top: 50%;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    cursor: pointer;
    >svg{
        @include centered;
        width: 16px;
    }
}
.MenuItem{
    padding: 8px 32px;
    display: flex;
    flex-direction: row;
    border-radius: 100px;
    cursor: pointer;
    column-gap: 16px;
    transition: background .3s ease;
    align-items: center;
    >.MenuItem_link{
        text-decoration: none;
        font-weight: 600;
        padding: 1.5px 0;
        color: $primary;
    }
    >.MenuItem_create{
        display: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: white;
        position: relative;
        transition: all .3s ease;
        > svg{
            @include centered;
            display: none;
            width: 100%;
            height: 100%;
        }
    }
    &:hover{
        >.MenuItem_create{
            display: block;
            >svg{
                display: block;
            }
        }
    }
    &:hover, &.MenuItem__active{
        background-color: $primary;
        > .MenuItem_link{
            color: white;
        }
    }
    &.Menu__canCreate:hover{
        padding-right: 8px;
    }
    &.MenuItem__disabled{
        cursor: not-allowed;
        opacity: .7;
        >*{
            cursor: not-allowed;
        }
        &:hover{
            background-color: $grey300;
        }
    }
}
.MenuItemPlanned{
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "soon soon" "name info";
    margin-top: 4px;
    column-gap: 8px;
    padding-left: 32px;
    @include desktopFhd{
        column-gap: clampFhd(4, 8);
        padding-left: clampFhd(16, 32);
    }
    @include desktopHd{
        column-gap: 4px;
        padding-left: 16px;
    }

    cursor: not-allowed;
    .MenuItemPlanned_soon{
        grid-area: soon;
        @include p__xsmall;
        color: $grey200;
        text-transform: uppercase;
        font-weight: 600;
    }
    .MenuItemPlanned_name{
        grid-area: name;
        color: $primary200;
        font-weight: 600;
    }
}

@include desktopFhd{
    .Menu{
        left: vwFhd(84);
        top: vwFhd(84);
        height: calc(100vh - vwFhd(84));
        width: vwFhd(432);
        &.Menu__narrow{
            width: clampFhd(72, 108);
            .Menu_in{
                margin-right: 0;
                padding: 0;
                width: calc(100% - vwFhd(30));
            }
        }
    }
    .Menu_in{
        // margin: clampFhd(32, 48) clampFhd(74, 114) clampFhd(32, 24) clampFhd(24, 30);
        margin: vwFhd(48) vwFhd(114) vwFhd(24) vwFhd(30);
    }
    .Menu_items{
        >ul{
            row-gap: vwFhd(12);
        }
    }
    .Menu_heading{
        padding-left: vwFhd(34);
    }
    .MenuItem{
        padding: vwFhd(8) vwFhd(32);
        column-gap: vwFhd(16);
        >.MenuItem_create{
            width: vwFhd(24);
            height: vwFhd(24);
        }
    }
    .Menu_cover{
        width: vwFhd(84);
        height: calc(100vh - vwFhd(84));
        right: vwFhd(-114);
        top: vwFhd(-48);
    }
}
@include desktopHd{
    .Menu{
        left: 56px;
        top: 56px;
        width: 282px;
        height: calc(100vh - 56px);
        &.Menu__narrow{
            width: 72px;
            .Menu_in{
                padding: 0;
                width: calc(100% - 24px);
            }
        }
        .Menu_in{
            margin: 32px 74px 32px 24px;
        }
    }
    .Menu_heading{
        // margin: 0 108px 42px 34px;
        // margin: 0 0 24px 16px;
        margin-left: 16px;
    }
    .MenuItem{
        padding: 8px 16px;
        column-gap: 12px;
        >.MenuItem_create{
            width: 18px;
            height: 18px;
            > svg{
                @include centered;
                width: 7.5px;
                height: 7.5px;
            }
        }
        &.Menu__canCreate:hover{
            padding-right: 8px;
        }
    }
    .Menu_cover{
        background-color: white;
        width: 50px;
        height: calc(100vh - 56px);
        right: -74px;
        top: -32px;
        border-radius: 48px 0px 0px 48px;
    }
    .Menu_toggle{
        width: 32px;
        height: 32px;
        right: -24px;
        cursor: pointer;
        >svg{
            @include centered;
            width: 14px;
            max-height: 16px;
        }
    }
}

@media print {
    .Menu{
        width: 0;
        display: none;
    }
 }
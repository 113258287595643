@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';

.Tabs{
    width: 100%;
    display: grid;
    column-gap: 24px;
    .Tab{
        @include p__center;
        cursor: pointer;
    }
    .Tabs_line, .Tabs_line__active{
        grid-row: 2;
        height: 2px;
        background-color: $auxilary;
        margin-top: 16px;
        position: relative;
    }
    .Tabs_line__active{
        background-color: $primary;
    }
    &.Tabs__flowcolumn{
        row-gap: 24px;
        column-gap: 24px;
    }
}
@import "src/style/variables";
@import "src/style/mixins";

.new-project-maincard{
    overflow: visible;
    height: 100%;
}
.selected-plan{
    display: grid;
    grid-template-columns: 1fr auto 15px;
    grid-template-rows: 24px;
    column-gap: 24px;
}
.selected-plan p{
    margin: 0;
    text-transform: capitalize;
}
.selected-plan p.bold{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.selected-plan > .plan-setup{
    position: relative;
}
.selected-plan > .plan-setup > img{
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .3s ease;
}
.selected-plan > .plan-setup > img:last-child, .selected-plan > .plan-setup:hover > img:first-child{
    opacity: 0;
}
.selected-plan > .plan-setup:hover > img:last-child{
    opacity: 1;
}
.project-new-domain, .project-new-location, .project-new-competitors, .project-new-gauth{
    height: calc(100% - 250px - 48px);
    padding-top: 250px;
    position: relative;
    overflow: visible;
}
@include desktopFhd{
.project-new-domain, .project-new-location, .project-new-competitors, .project-new-gauth{
    // height: calc(100% - 250px - 48px);
    padding-top: clampFhd(138, 250);
}
@include desktopHd{
    .project-new-domain, .project-new-location, .project-new-competitors, .project-new-gauth{
        padding-top: 138px;
    }
}
}
.project-new-location{
    padding-top: 150px;
    /* padding: 150px 20% 48px; */
    height: calc(100% - 48px - 150px);
    h5{
        span{
            @include h5;
            font-weight: 600;
        }
    }
}
.project-new-location-in{
    max-width: 408px;
    margin: auto;
    > div:first-child{
        margin: 48px auto 32px;
    }
}
@include desktopFhd{
    .project-new-location{
        padding-top: clampFhd(98, 150);
        h5{
            max-width: clampFhd(272, 408);
            margin: auto;
            br:first-of-type{
                display: none;
            }
        }
        .project-new-location-in{
            max-width: clampFhd(272, 408);
            > div:first-child{
                margin: clampFhd(32, 48) auto clampFhd(16, 32);
            }
        }
    }
}
.plan-popular{
    overflow: visible;
}
.project-new-gauth{
    padding-top: 209px;
    /* padding: 209px 20% 48px; */
    height: calc(100% - 48px - 209px);
}
.project-new-gauth > h5{
    margin-bottom: 48px;
}
.project-new-gauth > .description{
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translate(-50%, 0);
}
.project-new-gauth > .search-select{
    max-width: 400px;
    margin: auto;
}
.project-new-competitors{
    padding: 48px;
    height: calc(100% - 48px - 48px);
    p{
        text-align-last: center;
        margin: 16px 0 20px;
    }
}
@include desktopFhd{
    .project-new-competitors{
        padding: clampFhd(32, 48);
        height: calc(100% - clampFhd(32, 48) - clampFhd(32, 48));
        p{
            text-align-last: center;
            margin: 16px 0 8px;
        }
    }
}
.project-new-domain > img.illustration, .project-new-location > img.illustration, .project-new-competitors > img.illustration, .project-new-gauth > img.illustration{
    position: absolute;
    left: -86px;
    top: 133px;
}
@include desktopFhd{
    .project-new-domain > img.illustration, .project-new-location > img.illustration, .project-new-competitors > img.illustration, .project-new-gauth > img.illustration{
        left: -86px;
        top: clampFhd(76, 133);
        height: clampFhd(240, 359);
    }
}
.project-new-competitors > img.illustration{
    left: -229px;
}
.project-new-gauth > img.illustration{
    left: -63px;
    bottom: 0;
    top: unset;
}
.project-new-gauth > img.illustration.domain{
    left: -99px;
}
.project-new-domain > .input-group{
    max-width: 408px;
    margin: auto;
}
.input-group.competitors-group{
    position: relative;
    border: 1px solid #EFECFA;
    background-color: white;
    border-radius: 100px;
    height: 34px;
    padding: 4px;
}
.input-group.competitors-group > .list{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    column-gap: 4px;
    overflow-x: scroll;
}
.input-group.competitors-group > .counter{
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: 0;
    background-color: #2D0E6B;
    width: 42px;
    height: 42px;
}
.input-group.competitors-group > .counter > p{
    color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}
.input-group.competitors-group > .list > input.input{
    background-color: rgba(0, 0, 0, 0);
    border: rgba(0, 0, 0, 0);
    border-radius: 50%;
    min-width: 300px;
}
.input-group.competitors-group > .list > .competitor{
    white-space: nowrap;
    padding: 8px 32px 8px 16px;
    background-color: #EFECFA;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    border: none;
    color: #2D0E6B;
    position: relative;
}
.input-group.competitors-group > .list > .competitor::after{
    content: url('data:image/svg+xml;utf8,<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.41762 0.520084L1.34637 0.458571C1.09767 0.274031 0.744714 0.294495 0.519137 0.51999C0.271001 0.768034 0.27096 1.17024 0.519043 1.41833L2.10133 2.99972L0.521207 4.58047L0.459693 4.65171C0.275149 4.90039 0.295653 5.25329 0.521207 5.47881C0.769317 5.72688 1.17158 5.72688 1.41969 5.47881L3.0001 3.89835L4.58173 5.48122L4.65298 5.54273C4.90168 5.72727 5.25463 5.70681 5.48021 5.48131C5.72835 5.23327 5.72839 4.83107 5.48031 4.58297L3.89802 3.00057L5.47899 1.42016L5.5405 1.34891C5.72505 1.10023 5.70454 0.747334 5.47899 0.521816C5.23088 0.273746 4.82861 0.273746 4.5805 0.521816L2.99925 2.10194L1.41762 0.520084Z" fill="white"/></svg>');
    position: absolute;
    padding: 5px;
    line-height: 4px;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    right: 8px;
    top: 9px;
    background-color: #2D0E6B;
    color: white;
}
.project-new-competitors .hint-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 32px;
    column-gap: 32px;
    row-gap: 32px;
}
.project-new-competitors .hint-list .competitor{
    display: grid;
    grid-template-columns: 40px 1fr;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    column-gap: 16px;
    padding: 0;
}
.project-new-competitors .hint-list .competitor > .competitor-img{
    grid-column: 1;
    border-radius: 40px;
    grid-row: 1 / span 2;
    width: 40px;
    height: 40px;
    background-color: #EFECFA;
    overflow: hidden;
}
.project-new-competitors .hint-list .competitor > .competitor-img > img{
    width: 100%;
    height: auto;
}
.project-new-competitors .hint-list .competitor > p{
    margin: 0;
}
.project-new-competitors .hint-list .competitor > p.competitor-name{
    font-weight: 600;
}
.project-new-competitors .hint-list .competitor > p.competitor-state{
    font-size: 12px;
    line-height: 18px;
}
.project-new-keywords{
    width: 100%;
    height: 100%;
    div{
        grid-template-columns: repeat(8, 1fr);
    }
}
.project-new-keywords > *{
    height: 100%;
    margin: 0;
    padding: 0;
}
.project-new-keywords .keywords-left{
    height: calc(100% - 96px);
    p{
        margin: 16px 0 48px;
    }
}
@include desktopFhd{
    .project-new-keywords .keywords-left{
        height: calc(100% - clampFhd(64, 96));
    }
}
.project-new-keywords .import-out{
    padding: 59px 48px 48px 24px;
    width: calc(100% - 72px);
    position: relative;
    .keyword-import-collabim{
        height: 100%;
    }
    .keyword-import-file, .keywords-import-collabim{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        justify-content: center;
    }
    .keywords-import-collabim{
        position: relative;
        .change-api-key{
            margin: 0;
            position: absolute;
            bottom: 0;
            // margin-top: 116px;
            @include p__center;
            button{
                display: inline-flex;
            }
        }
        > .input-group, p{
            width: 100%;
            max-width: 324px;
        }
        hr{
            width: 24px;
            height: 2px;
            margin: 0 auto;
        }
        button{
            margin: 0;
        }
        label{
            display: block;
            margin-bottom: 8px;
            @include p__center;
        }
    }

    .keyword-import-file{
        
    }
}
.keywords-import-collabim-error{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    img{
        width: 100%;
        max-width: 200px;
        margin-bottom: 24px;
    }
    p{
        margin: 0 auto 24px;
        @include p__center;
        max-width: 324px;
    }
}
.project-new-keywords{
    .counter{
        font-weight: 600;
    }
    @include desktopFhd{
        .import-out{
            padding: 32px;
        }
    }
}
.project-new-keywords .import-out .keyword-import-manual > p{
    padding: 0;
    display: inline-block;
    margin: 0;
    margin-bottom: 16px;
}
.project-new-keywords .import-out .keyword-import-manual > p.counter{
    float: right;
}
.project-new-keywords .import-out .keyword-import-manual textarea{
    width: calc(100% - 96px);
    padding: 48px;
    resize: none;
    border: none;
    height: 300px;
    @include desktopFhd{
        width: calc(100% - clampFhd(16, 96));
        padding: clampFhd(16, 32);
        height: clampFhd(242, 300);
    }
    @include desktopHd{
        padding: 16px;
        width: calc(100% - 32px);
    }
}
// .project-new-keywords .import-out .keywords-import-collabim, .import-out .keyword-import-file{
//     // position: absolute;
//     // top: 50%;
//     // transform: translate(0%, -50%);
//     height: 100%;
//     justify-content: center;
//     width: calc(100% - 72px);
// }
.keywords-import-file .btn:not(.btn-send){
    background-color: #fff;
    border-color: #2D0E6B;
    color: #2D0E6B;
}
.keywords-import-file .btn-explain{
    display: none;
}
.keywords-import-file .btn:not(.btn-send):hover{
    color: #fff;
    background-color: #2D0E6B;
}
.new-project-main-plan{
    max-width: 948px;
    margin: auto;
    padding-top: 96px;
}

  



.project-content .input-group input{
    margin: 24px 0 0 0;
}
.projects-main{
    margin: auto;
    width: 840px;
    margin-top: 132px;
}
.project-content{
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
    padding: 108px;
}
.project-content.project-content-glogin{
    padding: 40px 108px;
    text-align: center;
}
.projects-out{
    position: relative;
    height: calc(100% - 128px);
}
.projects-out > .steps{
    /* position: absolute;
    bottom: 40px;
    margin: auto;
    width: 100%;
    text-align: center; */
}
.steps > .step:disabled{
    /* opacity: 1; */
}
.steps > .step:not(:last-child):after{
    /* content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    transform: rotate(-135deg);
    margin-left: 8px;
    border: 2px solid #B4B4B4;
    border-style: none none solid solid; */
}
.steps > .step.step-done{
    /* color: #330A70; */
}
.steps > .step.step-done::after{
    /* border-color: #330A70; */
}
.projects-out > .steps > .step::after{
    /* content: ""; */
}
.projects-out hr{
    border: none;
    background-color: #EFECFA;
    height: 1px;
    margin: 16px 0;
}
.projects-out hr.bold{
    height: 2px;
}
.input-group-terms:not(:last-child){
    /* margin-bottom: 8px; */
}
.input-group-terms, .input-group-terms a{
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    color: #B4B4B4;
    margin-top: 0;
    margin-bottom: 0;
}
.project-content .input-group-terms input{
    margin: 0 16px 0 0;
}
.input-group-terms a{
    color: #2D0E6B;
    text-decoration: none;
}
.new-project-pagination{
    margin-top: 64px;
    display: grid;
    grid-template-columns: auto 1fr auto;
}
.new-project-pagination > :last-child{
    grid-column: 3;
}
.new-project-pagination.new-project-pagination-single{
    grid-template-columns: 1fr auto 1fr;
}
.new-project-pagination.new-project-pagination-single > :last-child{
    grid-column: 2;
}
.project-new-inner h1 > .secondary{
    color: #B4B4B4;
}
.project-new-inner p.primary{
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #2d0e6b;
}
.competitors-header{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
}
.competitors-header > p.primary{
    padding-top: 10px;
}
.project-new-inner .competitors-header > span{
    text-align: right;
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
    color: #2d0e6b;
    text-transform: uppercase;
}
.project-new-inner .competitors-header > span.count{
    font-size: 24px;
}
.competitors-header > p{
    color: #B4B4B4;
}
.competitors-header > p.primary, .competitors-header > p{
    margin: 0;
}
.project-content > .new-project-section-competitors{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
}
.project-content > .new-project-section-competitors > .competitor{
    display: grid;
    grid-template-columns: 32px 1fr;
    column-gap: 16px;
    grid-template-rows: repeat(2, auto);
}
.project-content > .new-project-section-competitors > .competitor > .favicon{
    grid-column: 1;
    grid-row: 1 / span 2;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #67DF42;
}
.project-content > .new-project-section-competitors > .competitor > .favicon > img{
    width: 100%;
    height: 100%;
}
.project-content > .new-project-section-competitors > .competitor > div > p{
    margin: 0;
}
.project-content > .new-project-section-competitors > .competitor > .domain > p{
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    color: #2d0e6b;
}
.project-content > .new-project-section-competitors > .competitor > .state > p{
    font-family: Poppins;
    font-weight: normal;
    font-size: 8px;
    text-align: left;
    color: #67df42;
}
.project-new-overview-grid{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 8px;
}
.project-new-overview-grid > p{
    margin: 0;
}
.project-new-overview-grid > p.primary:not(:nth-last-child(2)){
    text-align: right;
    text-align-last: right;
}
.project-new-overview-grid > hr{
    grid-column: 1 / span 2;
    margin: 8px 0;
}
.plan-selector-wide{
    max-width: 950px;
    margin: auto;
}
.project-plan{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    > *{
        margin: 0;
    } 
    > *, p, h5, h6, .text-center{
        text-align: justify;
        text-align-last: center;
    }
    .color-primary{
        color: $secondary;
        font-weight: 600;
    }
    .additional{
        margin: 48px auto 64px;
    }
    // > h3, > p, .paydate{
    // }
}
.project-plan .paydate{
    // margin: 64px 0 36px;
}
.project-plan .plan-popular{
    overflow: visible;
    position: relative;
}
.project-plan .card:not(.card-selected){
    border: 2px solid rgba(0, 0, 0, 0);
}
.project-plan .pill{
    position: absolute;
    top: -13px;
    left: 50%;
    background-color: $secondary100;
    color: $secondary;
    padding: 4px 16px;
    border-radius: 100px;
    font-weight: 600;
    transform: translate(-50%, 0);
}
.project-plan .slider-out{
    margin-left: 16px;
}
.project-plan .custom-plan{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    > span{
        color: $auxilary;
    }
}
.plan-selector-btn{
    justify-content: center;
}
.project-plan .custom-plan button{
    padding-top: 0;
    padding-bottom: 0;
    display: inline-block;
}
.project-plan .custom-plan button:not(.active):not(:hover) > h6{
    color: #C0B7D3;
}
.project-plan{
    .card-plan{
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 16px;
        p{
            margin: 0;
        }
        h5{
            margin-top: 16px;
        }
    }
    .plan-list{
        margin-top: 13px;
    }
    .plan-list > div{
        position: relative;
        max-width: 948px;
    }
    .plan-selector-illustration{
        position: absolute;
        left: 0;
        bottom: -20px;
    }
    button.select-plan{
        margin: 32px auto 0;
    }
    .period-toggle{
        display: inline-flex;
        justify-content: center;
        > div{
            // margin: auto;
            position: relative;
        }
        .toggle-explain{
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(100%, 0);
            font-weight: 600;
            color: $secondary;
        }
    }
}
.new-projects-main{
    padding-top: 200px;
    overflow: visible;
    .text-center{
        @include p__center;
    }
    @include desktopFhd{
        padding-top: clampFhd(130, 200);
    }
    @include desktopHd{
        padding-top: 130px;
    }
}
.main.main-wide.main-wide-centered{
    padding-left: 0;
}
@import 'src/style/variables.scss';
@import 'src/style/mixins.scss';
.SidePanel{
    width: 84px;
    position: fixed;
    // height: 100vh;
    padding-top: 180px;
    padding-bottom: 60px;
    height: calc(100vh - 240px);
    top: 0;
    left: 0;
    // display: grid;
    // grid-template-rows: auto 1fr auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    .SidePanel_bot, .SidePanel_top{
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }
    .SidePanel_top{
        row-gap: 0;
        >:first-child{
            margin-bottom: 50px;
        }
    }

    .SidePanel_btn{
        height: 48px;
        width: 84px;
        position: relative;
        display: block;
        // border-left: 4px solid rgba(0, 0, 0, 0);
        transition: border .5s ease-in-out;
        >svg{
            @include centered;
            // position: absolute;
            // left: 30px;
            // top: 12px;
            display: inline;
            opacity: 1;
            transition: opacity .5s ease-in-out;
            width: 24px;
            height: 24px;
            &:first-child{
                opacity: 1;
            }
            &:last-child{
                opacity: 0;
            }
        }
        &.SidePanel_btn__active::before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 4px;
            background-color: $primary;
            border-radius: 4px;
        }
        &:hover, &.SidePanel_btn__active{
            >svg{
                &:first-child{
                    opacity: 0;
                }
                &:last-child{
                    opacity: 1;
                }
            }
        }
    }
}
@include desktopFhd{
    .SidePanel{
        width: vwFhd(84);  
        .SidePanel_btn{
            height: vwFhd(48);
            width: vwFhd(84);
            >svg{
                width: vwFhd(24);
                height: vwFhd(24);
            }
        }
    }
}
@include desktopHd{
    .SidePanel{
        width: 56px;
        padding: 130px 0 48px;
        height: calc(100vh - 178px);
        .SidePanel_bot{
            row-gap: 48px;
        }
        .SidePanel_top{
            row-gap: 8px;
            >a:first-child{
                margin-bottom: 40px;
            }
        }
        .SidePanel_btn{
            width: 53px;
            height: 32px;
            border-left-width: 3px;
            >svg{
                @include centered;
                width: 20px;
                height: 20px;
            }
        }
    }
}
.SidePanelExplain{
    padding: 104px 108px 48px;
    position: fixed;
    top: 0;
    left: 0;
    width: 408px;
    height: calc(100vh - 152px);
    background: linear-gradient(180deg, #EFECFA 0%, rgba(239, 236, 250, 0) 100%);
    border-radius: 0px 32px 32px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    .Logo{
        width: 150px;
        height: 32px;
        margin-bottom: 56px;
    }
    .LinkBack{
        margin-bottom: 16px;
        .Icon{
            transform: rotate(-90deg);
            width: 20px;
            height: 20px;
        }
    }
    .Content_single{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        grid-template-areas: "img heading" "img content";
        column-gap: 24px;
        row-gap: 8px;
        .Content_icon{
            grid-area: img;
            width: 24px;
            height: 24px;
            padding: 30px;
            border-radius: 50%;
            background-color: $auxilary;
            svg{
                width: 100%;
                height: 100%;
            }
        }
        .Content_body{
            text-align: left;
        }
    }
    .Footer{
        position: fixed;
        bottom: 48px;
        width: 624px;
        display: flex;
        flex-direction: row;
        column-gap: 32px;
    }
}
@include desktopFhd{
    .SidePanelExplain{
        padding: clampFhd(72, 104) clampFhd(60, 108) clampFhd(32, 48) clampFhd(72, 104);
        width: clampFhd(284, 408);
        height: calc(100vh - clampFhd(104, 152));
        row-gap: clampFhd(32, 48);
        .Logo{
            width: clampFhd(115, 150);
            height: clampFhd(24, 32);
            margin-bottom: clampFhd(40, 56);
        }
        .LinkBack{
            margin-bottom: 16px;
            .Icon{
                width: clampFhd(16, 20);
                height: clampFhd(16, 20);
            }
        }
        .Content_single{
            column-gap: clampFhd(16, 24);
            row-gap: clampFhd(0, 8);
            .Content_icon{
                width: clampFhd(16, 24);
                height: clampFhd(16, 24);
                padding: clampFhd(20, 30);
            }
            h6{
                @include p;
                font-weight: 600;
            }
            div{
                @include p__small;
            }
        }
        .Footer{
            bottom: clampFhd(32, 48);
            width: clampFhd(416, 624);
            column-gap: clampFhd(24, 32);
        }
    }
}
@media print{
    .SidePanel{
        display: none;
    }
}
@use 'sass:math';
@import 'src/style/variables.scss';


@mixin h1{
    font-size: 96px;
    line-height: 115.2px;
    @include desktopHd{
      font-size: 64px;
      line-height: 76.8px;
    }
    /* margin: 0 0 30px 0; */
}
@mixin h2{
    font-size: 64px;
    line-height: 76.8px;
    margin: 0 0 16px 0;
    @include desktopFhd{
      font-size: clamp(40px, vwFhd(64), 64px);
      line-height: clamp(48px, vwFhd(76.8), 76.8px);
      margin: 0 0 vwFhd(16) 0;
    }
    @include desktopHd{
      font-size: 40px;
      line-height: 48px;
    }
}
@mixin h3{
    font-size: 40px;
    line-height: 48px;
    @include desktopFhd{
      font-size: clamp(32px, vwFhd(40), 40px);
      line-height: clamp(38px, vwFhd(48), 48px);
    }
    @include desktopHd{
      font-size: 32px;
      line-height: 38.4px;
    }
}
@mixin h4{
    font-size: 32px;
    line-height: 48px;
    @include desktopFhd{
      font-size: clamp(24px, vwFhd(32), 32px);
      line-height: clamp(29px, vwFhd(48), 48px);
    }
    @include desktopHd{
      font-size: 24px;
      line-height: 28.8px;
    }
}
@mixin h5{
    font-size: 24px;
    line-height: 36px;
    @include desktopFhd{
      font-size: clamp(20px, vwFhd(24), 24px);
      line-height: clamp(30px, vwFhd(36), 36px);
    }
    @include desktopHd{
      font-size: 20px;
      line-height: 30px;
    }
}
// @mixin h6{
//     font-size: 20px;
//     line-height: 30px;
//     @include desktopFhd{
//       font-size: clamp(16px, vwFhd(20), 20px);
//       line-height: clamp(24px, vwFhd(30), 30px);
//     }
//     @include desktopHd{
//       font-size: 16px;
//       line-height: 24px;
//     }
// }
@mixin h6{
    font-size: 16px;
    line-height: 24px;
    @include desktopFhd{
      font-size: clamp(14px, vwFhd(16), 16px);
      line-height: clamp(21px, vwFhd(24), 24px);
    }
    @include desktopHd{
      font-size: 14px;
      line-height: 21px;
    }
}
@mixin p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #2D0E6B;
    text-align: justify;
    text-align-last: left;
    @include desktopFhd{
      font-size: clamp(12px, vwFhd(14), 14px);
      line-height: clamp(18px, vwFhd(21), 21px);
    }
    @include desktopHd{
      font-size: 12px;
      line-height: 18px;
    }
}
@mixin p__small{
  font-size: 12px;
  line-height: 18px;
  @include desktopFhd{
    font-size: clamp(10px, vwFhd(12), 12px);
    line-height: clamp(15px, vwFhd(18), 18px);
  }
  @include desktopHd{
    font-size: 10px;
    line-height: 15px;
  }
}
@mixin p__xsmall{
  font-size: 10px;
  line-height: 15px;
  @include desktopFhd{
    font-size: clamp(8px, vwFhd(10), 10px);
    line-height: clamp(12px, vwFhd(15), 15px);
  }
  @include desktopHd{
    font-size: 8px;
    line-height: 12px;
  }
}
@mixin p__center{
  text-align: center;
  text-align-last: center;
}

@mixin inputTerciary{
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 0;
  padding: 0;
}

@mixin centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin scrollbarHidden{
  $dimension:     0px;
  $trackColor:    rgba(255, 255, 255, 0);
  $thumbColor:    rgba(255, 255, 255, 0);
  scroll-margin-top: rem(0);

  /* Firefox */
  scrollbar-color: $thumbColor $trackColor; /* thumb and track color */
  scrollbar-width: thin; /* value: none, thin, auto */
  /* Webkit - Chrome */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: $dimension;
    width: $dimension;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumbColor;
  }
  &::-webkit-scrollbar-track {
    background: $trackColor;
  }
}

@mixin input{
  all: unset;
  @include p;
  @include p__center;
  background-color: $textBackground;
  border-radius: 100px;
  font-weight: 400;
  padding: 7px 15px;
  color: $primary;
  border: 1px solid $textBackground;
  width: calc(100% - 32px);
  &.input__secondary{
    border-radius: 0;
    background-color: $transparent;
    border: none;
    border-bottom: 1px solid $auxilary;
    padding: 4px 0;
    &:hover, &:active{
      border-bottom-color: $primary;
    }
  }
  @include desktopFhd{
  }
  &::placeholder{
    color: $primary100;
    opacity: 1;
  }
  &:hover::placeholder{
    color: $primary;
  }
  &:hover, &:active, &:focus{
    border-color: $auxilary;
  }
}
@mixin animate($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;
  -webkit-animation: $animation $duration $method $times;
}
@mixin keyframes($name){
  @keyframes #{$name}{
      @content;
  }
}

@mixin shimmer {
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1000px 100%;
    animation: shimmer-animation 2.2s linear infinite forwards;

  @-webkit-keyframes shimmer-animation {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer-animation {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
}
@mixin spinner{
  display: inline-block;
  box-sizing: border-box;
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  border: 0.25em solid white;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spin;
  animation: .75s linear infinite spin;
  @include keyframes(spin){
    from { 
      transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
  }
  // @include animate(spin, .75s, infinite, 1);
  @include animate(.75s, linear, infinite, spin);
}
@function vwFhd($size){
  $finalSize: math.div($size, 19.20);
  @return #{$finalSize}vw;
}

@function clampFhd($sizeHd, $sizeFhd){
  @return clamp(#{$sizeHd}px, #{vwFhd($sizeFhd)}, #{$sizeFhd}px);
}
@function clampHd($sizeHd){
  // TODO: kde je použito, nahradi nižší velikost
  // @return clamp(#{$sizeHd}px, #{vwFhd($sizeFhd)}, #{$sizeFhd}px);
  @return #{$sizeHd}px;
}
// @function rem($size) {
//   $remSize: $size;
//   $return: #{$remSize}px;
//   @return $return;
// }
// @include desktopFhd{
//   @function rem($size) {
//     $remSize: math.div($size, 19.20);
//     $return: #{$remSize}vw;
//     @return $return;
//   }
// }
// Extra small devices (portrait phones)
@mixin xsmall {
    @media (max-width: #{map-get($grid-breakpoints, xs)}) {@content;}
  }
  
  // Small devices (landscape phones)
  @mixin small {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {@content;}
  }
  
  // Medium devices (tablets)
  @mixin tablet {
    @media (max-width: #{map-get($grid-breakpoints, md)}) {@content;}
  }
  
  // Large devices (desktops)
  // HD
  @mixin desktop {
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {@content;}
  }
  
  // Extra large devices (large desktops)
  // FHD + (default)
  @mixin widescreen {
    @media (min-width: #{map-get($grid-breakpoints, xl)}) {@content;}
  }

  @mixin desktopFhd{
    @media (max-width: #{map-get($grid-breakpoints, xxl)}) {@content;}
  }
  @mixin desktopHd{
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {@content;}
  }
  
  @mixin popup-small {
    @media (max-width: $popup-screen-sm) {@content;}
  }
  
  @mixin popup-tablet {
    @media (max-width: $popup-screen-md) {@content;}
  }
  
  @mixin popup-desktop {
    @media (max-width: $popup-screen-lg) {@content;}
  }
  
  @mixin popup-widescreen {
    @media (max-width: $popup-screen-xl) {@content;}
  }
.react-datepicker{
    width: 100%;
    background-color: #fff;
    border: none;
    border-radius: 0;
}
.react-datepicker__month-container, .react-datepicker__month{
    width: 100%;
    padding: 0;
    margin: 0;
}
.react-datepicker__header{
    background-color: #FFF;
    border-bottom: none;
}
.react-datepicker__current-month{
    color: #2D0E6B;
    font: normal normal 600 12px/18px Poppins;
    text-transform: capitalize;
}
.react-datepicker__week, .react-datepicker__day-names{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}
.react-datepicker__day-name{
    font: normal normal 600 10px/16px Poppins;
    color: #2D0E6B;
    text-transform: capitalize;
}
.react-datepicker__day{
    margin: 3.5px 0;
    padding: 0;
    border-radius: 0;
    line-height: 16px;
    color: #2D0E6B;
    font: normal normal 600 10px/16px Poppins;
    width: 100%;
}
.react-datepicker__day.react-datepicker__day--in-range, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
    background-color: #EFECFA;
}
.react-datepicker__day:hover{
    background-color: #2D0E6B;
    border-radius: 8px;
    color: #FFF;
}
.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start, .react-datepicker__day--selected:not(.react-datepicker__day--range-start){
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end, .react-datepicker__day--selected:not(.react-datepicker__day--range-start){
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;   
}
.react-datepicker__day--outside-month{
    color: #EFECFA;
}
.react-datepicker__current-month > .year{
    color: #EFECFA;
    margin-left: .5em;
}
.react-datepicker__navigation{
    border-radius: 50%;
    background-color: #EFECFA;
    border: 0px solid rgba(0, 0, 0, 0);
    width: 16px;
    height: 16px;
    text-indent: 0;
    line-height: unset;
}
.react-datepicker__navigation > img{
    width: 6px;
    height: 5px;
    padding: 5.5px;
}
.react-datepicker__navigation.react-datepicker__navigation--next > img{
    rotate: 180deg;
}